import React from "react"

export const HexOutline = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 64 57"
      fill="none"
    >
      <path
        d="M1.43873 25.6187L14.4856 3.29669C14.4857 3.29664 14.4857 3.29659 14.4857 3.29654C15.4064 1.72236 17.1074 0.75 18.9537 0.75H45.0469C46.8929 0.75 48.5937 1.72233 49.5152 3.2969L62.5612 25.6188C63.4795 27.1916 63.4796 29.1257 62.5613 30.6989C62.5613 30.699 62.5612 30.6991 62.5612 30.6992L49.5152 53.0229C49.5151 53.023 49.5151 53.0231 49.5151 53.0232C48.5935 54.5976 46.8928 55.57 45.0469 55.57H18.9538C17.1074 55.57 15.4064 54.5975 14.4859 53.0233C14.4858 53.0233 14.4858 53.0232 14.4858 53.0232L1.43876 30.6992C0.520404 29.1259 0.520432 27.1917 1.43873 25.6187Z"
        stroke="#EE7423"
        stroke-width="1.5"
      />
    </svg>
  )
}

export const HexFilled = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="auto"
      viewBox="0 0 48 43"
      fill="none"
    >
      <path
        d="M0.593277 18.9305L10.3787 2.18856C11.1711 0.833521 12.6328 0 14.2153 0H33.7851C35.3675 0 36.8288 0.833521 37.6219 2.18856L47.4066 18.9305C48.1978 20.2854 48.1978 21.9528 47.4066 23.308L37.6219 40.0513C36.8288 41.4064 35.3675 42.24 33.7851 42.24H14.2154C12.6328 42.24 11.1711 41.4064 10.3788 40.0513L0.593277 23.308C-0.197758 21.9528 -0.197758 20.2854 0.593277 18.9305Z"
        fill="#EE7423"
      />
    </svg>
  )
}

export const EligibilityIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="auto"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M26.55 5.1501H26.45C26.1 5.1501 25.75 5.1501 25.4 5.1501V4.0001C25.4 2.3001 24 0.850098 22.25 0.850098H7.15C5.4 0.900098 4 2.3001 4 4.0001V21.1001C4 21.7501 4.25 22.3501 4.7 22.8501L12 30.3501C12.45 30.8501 13.1 31.1001 13.8 31.1001H22.95C24.3 31.1001 25.45 30.0001 25.45 28.6001V28.1501L27.85 23.3001C27.95 23.1501 27.95 23.0001 27.95 22.8001L28 6.6001C28 5.8501 27.35 5.2001 26.55 5.1501ZM25.75 9.2001H22.95V7.3501C23.9 7.3501 24.8 7.3501 25.75 7.3501V9.2001ZM22.2 7.4001C22.25 7.4001 22.25 7.4001 22.2 7.4001C22.25 7.4001 22.25 7.4001 22.2 7.4001ZM11.9 27.0001L7.8 22.8001H11.9V27.0001ZM22.95 28.9001H14.15V21.9501C14.15 21.1501 13.5 20.5501 12.75 20.5501H6.25V4.0001C6.25 3.5001 6.65 3.1001 7.15 3.1001H22.35C22.85 3.1001 23.25 3.5001 23.25 4.0001V5.1001C22.9 5.1001 22.6 5.1001 22.25 5.1001C21.4 5.1001 20.75 5.7501 20.75 6.5501L20.7 22.8001C20.7 23.0001 20.75 23.1501 20.8 23.3001L23.25 28.1501V28.6001C23.2 28.7501 23.1 28.9001 22.95 28.9001ZM24.3 25.4001L22.9 22.5501V11.4501H25.7V22.5501L24.3 25.4001Z"
        fill="#F0833B"
      />
    </svg>
  )
}

export const FormularyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="auto"
      viewBox="0 0 28 23"
      fill="none"
    >
      <path
        d="M26.9939 13.7129L20.6209 4.60797C19.5369 3.06213 17.8112 2.23574 16.0612 2.23574C14.9626 2.23574 13.8494 2.56143 12.8771 3.24199C11.9195 3.91283 11.2389 4.83644 10.8598 5.85728C10.7188 2.97463 8.36115 0.680176 5.44447 0.680176C2.43543 0.680176 0 3.1156 0 6.12464V17.0136C0 20.0226 2.43543 22.458 5.44447 22.458C8.45351 22.458 10.8889 20.0226 10.8889 17.0136V9.76563C11.0494 10.1837 11.2438 10.5969 11.5112 10.9809L17.889 20.0858C18.9681 21.6317 20.6938 22.458 22.4487 22.458C23.5522 22.458 24.6605 22.1323 25.6327 21.4518C28.146 19.6921 28.7536 16.2261 26.9939 13.7129ZM7.77781 11.5691H3.11112V6.12464C3.11112 4.83644 4.15627 3.7913 5.44447 3.7913C6.73267 3.7913 7.77781 4.83644 7.77781 6.12464V11.5691ZM17.2473 13.7518L14.0584 9.19688C13.6841 8.66215 13.5382 8.01076 13.6549 7.36909C13.7667 6.72742 14.1264 6.16353 14.6612 5.78922C15.0744 5.49756 15.5605 5.34686 16.0612 5.34686C16.8633 5.34686 17.6119 5.73575 18.0688 6.392L21.2577 10.9469L17.2473 13.7518Z"
        fill="#F0833B"
      />
    </svg>
  )
}

export const PrescriptionIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="auto"
      viewBox="0 0 26 31"
      fill="none"
    >
      <path
        d="M24.45 7.66025L17.55 0.860254C17.15 0.460254 16.6 0.260254 16.05 0.260254H4.04999C2.34999 0.260254 0.899994 1.66025 0.899994 3.41025V27.4103C0.899994 29.1103 2.29999 30.5603 4.04999 30.5603H21.9C23.6 30.5603 25.05 29.1603 25.05 27.4103V9.16025C25.05 8.61025 24.85 8.06025 24.45 7.66025ZM17.6 4.06025L21.35 7.76025H17.6V4.06025ZM21.95 28.2603H4.04999C3.54999 28.2603 3.14999 27.8603 3.14999 27.3603V3.36025C3.14999 2.86025 3.54999 2.46025 4.04999 2.46025H15.35V8.86025C15.35 9.46025 15.85 10.0103 16.5 10.0103H22.85V27.3603C22.8 27.8603 22.4 28.2603 21.95 28.2603Z"
        fill="#F0833B"
      />
      <path
        d="M14.7018 20.2491L16.87 18.0808C17.0437 17.9072 17.0437 17.6258 16.87 17.4522L16.2415 16.8236C16.0678 16.65 15.7865 16.65 15.6129 16.8236L13.4446 18.9919L11.1124 16.6597C12.4254 16.4936 13.4446 15.385 13.4446 14.027C13.4446 12.5542 12.2507 11.3604 10.7779 11.3604H6.77802C6.53247 11.3604 6.33359 11.5592 6.33359 11.8048V18.9158C6.33359 19.1613 6.53247 19.3602 6.77802 19.3602H7.66689C7.91245 19.3602 8.11133 19.1613 8.11133 18.9158V16.6936H8.63215L12.1876 20.2491L10.0193 22.4174C9.84574 22.591 9.84574 22.8723 10.0193 23.046L10.6479 23.6746C10.8216 23.8482 11.1029 23.8482 11.2765 23.6746L13.4446 21.506L15.6129 23.6743C15.7865 23.8479 16.0678 23.8479 16.2415 23.6743L16.87 23.0457C17.0437 22.8721 17.0437 22.5907 16.87 22.4171L14.7018 20.2491ZM8.11133 13.1381H10.7779C11.2679 13.1381 11.6668 13.537 11.6668 14.027C11.6668 14.517 11.2679 14.9158 10.7779 14.9158H8.11133V13.1381Z"
        fill="#F0833B"
      />
    </svg>
  )
}

export const SafetyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="auto"
      viewBox="0 0 26 32"
      fill="none"
    >
      <path
        d="M23.55 3.94014L13.9 1.04014C13.3 0.840137 12.65 0.840137 12.05 1.04014L2.44999 3.94014C1.09999 4.34014 0.149994 5.59014 0.149994 6.99014V18.6401C0.149994 22.3401 3.59999 25.5901 6.54999 27.6901C8.39999 29.0401 10.3 30.0901 11.55 30.7401C12 31.0401 12.5 31.1401 13 31.1401C13.5 31.1401 14 31.0401 14.45 30.7901C15.7 30.1401 17.55 29.0901 19.45 27.7401C22.35 25.6401 25.85 22.3401 25.85 18.6901V6.99014C25.85 5.59014 24.9 4.34014 23.55 3.94014ZM23.6 18.6901C23.6 20.8401 21.65 23.3901 18.15 25.9401C16.4 27.1901 14.65 28.1901 13.45 28.8401C13.2 28.9901 12.85 28.9901 12.6 28.8401C11.4 28.2401 9.64999 27.2401 7.89999 25.9401C4.39999 23.3901 2.44999 20.8401 2.44999 18.6901V6.99014C2.44999 6.59014 2.74999 6.19014 3.14999 6.09014L12.8 3.19014C12.9 3.14014 13 3.14014 13.05 3.14014C13.1 3.14014 13.25 3.14014 13.3 3.19014L22.95 6.09014C23.35 6.19014 23.65 6.59014 23.65 6.99014V18.6901H23.6Z"
        fill="#F0833B"
      />
      <path
        d="M16.6 11.7902L10.85 16.4402L9.59998 14.6902C9.24998 14.1902 8.54998 14.0902 8.04998 14.4402C7.54998 14.7902 7.44998 15.4902 7.79998 15.9902L9.74998 18.6902C9.94998 18.9402 10.2 19.0902 10.5 19.1402C10.55 19.1402 10.6 19.1402 10.65 19.1402C10.9 19.1402 11.15 19.0402 11.35 18.8902L18 13.5402C18.5 13.1402 18.55 12.4402 18.15 11.9402C17.8 11.4902 17.1 11.4402 16.6 11.7902Z"
        fill="#F0833B"
      />
    </svg>
  )
}

export const ReminderIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="auto"
      viewBox="0 0 32 27"
      fill="none"
    >
      <path
        d="M30.25 19.3702L19.55 2.15352C18.7 1.00352 17.4 0.353516 16 0.353516C14.55 0.353516 13.25 1.00352 12.45 2.15352L1.74999 19.3702C0.749995 20.7202 0.599995 22.4702 1.34999 23.9702C2.09999 25.4702 3.6 26.4202 5.3 26.4202H26.7C28.4 26.4202 29.9 25.4702 30.65 23.9702C31.4 22.5202 31.25 20.7202 30.25 19.3702ZM28.65 22.9702C28.25 23.7202 27.55 24.1702 26.7 24.1702H5.3C4.45 24.1702 3.74999 23.7202 3.34999 22.9702C2.99999 22.2202 3.04999 21.3702 3.54999 20.7202L14.25 3.50352C14.65 2.95352 15.3 2.60352 16 2.60352C16.7 2.60352 17.35 2.90352 17.75 3.50352L28.45 20.7202C28.95 21.3702 29 22.2202 28.65 22.9702Z"
        fill="#F0833B"
      />
      <path
        d="M15.9999 9.65332C15.3999 9.65332 14.8499 10.1533 14.8499 10.8033V16.2533C14.8499 16.8533 15.3499 17.4033 15.9999 17.4033C16.6499 17.4033 17.1499 16.9033 17.1499 16.2533V10.7533C17.1499 10.1533 16.5999 9.65332 15.9999 9.65332Z"
        fill="#F0833B"
      />
      <path
        d="M15.9999 18.7202C15.3999 18.7202 14.8499 19.2202 14.8499 19.8702V20.1202C14.8499 20.7202 15.3499 21.2702 15.9999 21.2702C16.6499 21.2702 17.1499 20.7702 17.1499 20.1202V19.8202C17.1499 19.2202 16.5999 18.7202 15.9999 18.7202Z"
        fill="#F0833B"
      />
    </svg>
  )
}
