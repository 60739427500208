import React, { useContext, useEffect, Fragment } from "react"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"

import Layout from "layout"
import Container from "layout/Container"
import ButtonGroup from "../../../Elements/ButtonGroup"

import { generateFormField } from "elements/Form/services/form"
import { MedEnsureContext } from "../../MedEnsureContext/MedEnsureContext"

import mechanicsData from "./utils/mechanics.json"
import {
  EligibilityIcon,
  FormularyIcon,
  HexFilled,
  HexOutline,
  PrescriptionIcon,
  ReminderIcon,
  SafetyIcon,
} from "./utils/svg"
import classNames from "classnames"
import styles from "./utils/mechanics.module.scss"

let { parseFormField } = require("../../../../services/airtable")

const Mechanics = ({ pageContext }) => {
  const { medEnsureState, medEnsureDispatch } = useContext(MedEnsureContext)
  let formFields = pageContext.formFields
  let sectionFormFields = parseFormField(formFields)

  const handleSubmitMedEnsureMechanics = (values) => {
    medEnsureDispatch({
      type: "SAVE_AGREE_TO_MECHANICS",
      payload: {
        ...values,
      },
    })
    medEnsureDispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...medEnsureState,
        ...values,
      },
    })
    navigate(pageContext.nextPath)
  }

  const handleIcons = (name) => {
    switch (name) {
      case "Eligibility":
        return <EligibilityIcon />
      case "Medicine Coverage":
        return <FormularyIcon />
      case "Prescriptions and Other Documents":
        return <PrescriptionIcon />
      case "Patient Safety":
        return <SafetyIcon />
      case "Reminders":
        return <ReminderIcon />
      default:
        return null
    }
  }

  useEffect(() => {
    medEnsureDispatch({ type: "GET_CONTEXT_FROM_SESSION" })
    // eslint-disable-next-line
  }, [])

  let pageContent = pageContext?.pageContent?.[0]?.content
  return (
    <Layout
      title={pageContext?.module?.title}
      subtitle={pageContext?.module?.subtitle}
      seoTitle={pageContext?.module?.seoTitle}
      pageContext={pageContext}
    >
      <Container mobile={12} tablet={10} desktop={6} isCentered>
        <Formik
          initialValues={{ ...medEnsureState }}
          onSubmit={handleSubmitMedEnsureMechanics}
        >
          {({ values, setFieldValue }) => {
            let isNextDisabled = formFields.reduce((isNextDisabled, field) => {
              if (!!field.required)
                return isNextDisabled || !values?.[field?.name]?.length
              return isNextDisabled
            }, false)
            return (
              <Form>
                {sectionFormFields.map((section) => (
                  <Fragment>
                    <div className={classNames(styles["lists__wrapper"])}>
                      {mechanicsData.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className={classNames(styles["list"])}
                          >
                            <div
                              className={classNames(styles["label__wrapper"])}
                            >
                              <div
                                className={classNames(styles["hex__container"])}
                              >
                                <div
                                  className={classNames(styles["hex__wrapper"])}
                                >
                                  <div
                                    className={classNames(
                                      styles["hex__filled"]
                                    )}
                                  >
                                    <HexFilled />
                                  </div>
                                  <div
                                    className={classNames(
                                      styles["hex__outlined"]
                                    )}
                                  >
                                    <HexOutline />
                                  </div>
                                  <div
                                    className={classNames(styles["hex__icon"])}
                                  >
                                    {handleIcons(item?.name)}
                                  </div>
                                </div>
                              </div>
                              <div
                                className={classNames(
                                  "has-text-secondary has-text-weight-bold",
                                  styles["list__title"]
                                )}
                              >
                                {item?.name}
                              </div>
                            </div>
                            <div
                              className={classNames(styles["list__paragraph"])}
                            >
                              <div
                                className={classNames(
                                  "has-text-secondary has-text-weight-bold",
                                  styles["list__title"]
                                )}
                              >
                                {item?.name}
                              </div>
                              <ul
                                className={classNames(styles["text__wrapper"])}
                              >
                                {item?.content?.map((list, index) => {
                                  if (Array.isArray(list)) {
                                    return (
                                      <ul
                                        className={classNames(
                                          styles["text__list"]
                                        )}
                                      >
                                        {list.map((listItem, listIndex) =>
                                          !Array.isArray(listItem) ? (
                                            <li
                                              className={classNames(
                                                styles["text__nested"]
                                              )}
                                            >
                                              <div>•</div>
                                              <p>{listItem}</p>
                                            </li>
                                          ) : (
                                            <ul
                                              className={classNames(
                                                styles["text__list"]
                                              )}
                                            >
                                              {listItem.map(
                                                (nestedItem, nestedIndex) => (
                                                  <li
                                                    className={classNames(
                                                      styles["text__nested"]
                                                    )}
                                                  >
                                                    <div>•</div>
                                                    <p>{nestedItem}</p>
                                                  </li>
                                                )
                                              )}
                                            </ul>
                                          )
                                        )}
                                      </ul>
                                    )
                                  } else {
                                    return (
                                      <li
                                        key={index}
                                        className={classNames(styles["text"])}
                                      >
                                        <div>•</div>
                                        <p>{list}</p>
                                      </li>
                                    )
                                  }
                                })}
                              </ul>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                    <div className="content">
                      {section?.fields.map((field) => {
                        if (!field?.referenceAnswer) {
                          return (
                            <Fragment>
                              {generateFormField({
                                formFields: section?.fields,
                                formField: { ...field },
                                values,
                                setFieldValue,
                              })}
                              {!!field?.addDividerAfterField && (
                                <hr className="has-background-light" />
                              )}
                            </Fragment>
                          )
                        }
                        return null
                      })}
                    </div>
                  </Fragment>
                ))}
                <ButtonGroup
                  back={{
                    link: pageContext.backPath,
                  }}
                  next={{
                    label: `Next: ${pageContext.module.next}`,
                    link: pageContext.nextPath,
                    disabled: !!isNextDisabled,
                    type: "submit",
                  }}
                />
              </Form>
            )
          }}
        </Formik>
      </Container>
    </Layout>
  )
}

export default Mechanics
